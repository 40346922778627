body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .main {
    color: var(--text);
    background: var(--bg);
    font-size: var(--base-fontsize);
    line-height: var(--line-height);
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;
    padding: 0 .5rem;
    overflow-x: hidden;
    word-break: break-word;
    overflow-wrap: break-word;
  }